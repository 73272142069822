<template>
  <button type="button" class="btn btn-info" @click.prevent="click">
    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
    <i v-else class="bi bi-check-square"></i>
  </button>
</template>

<script>
  export default {
    name: 'SaveButton',
    props: {
      loading: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      click() {
        this.$emit('onClick')
      }
    }
  }
</script>
