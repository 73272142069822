<template>

	<div class="d-flex card h-auto">
		<div v-if="!editMode" class="row">
			<div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5">
				<div class="avatar" :style="imageStyle"></div>
			</div>
			<div class="col-1 h-100">
				<div class="vertical-rule"></div>
			</div>
			<div class="col-xl-8 col-lg-8 col-md-7 col-sm-6 col-6">
				<div class="card-body text-left d-flex flex-column p-0 pt-2">
					<h5 class="card-title">{{ user.name }}</h5>
					<h5 class="card-title">{{ user.position }}</h5>
					<h5 class="card-text">{{ user.email }}</h5>
					<p v-if="user.bio" class="card-text">{{ user.bio }}</p>

					<div class="mt-auto">
						<button type="button" class="btn btn-info" @click.prevent="switchMode">
              <i class="bi bi-pencil-square"></i>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="row">
			<div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5">
				<label for="image_upload" class="w-100">
					<div class="avatar w-100" :style="imageStyle"></div>
				</label>
				<input id="image_upload" type="file" class="form-control d-none" @change="onImageSelect">
			</div>
			<div class="col-1">
				<div class="vertical-rule"></div>
			</div>
			<div class="col-xl-8 col-lg-8 col-md-7 col-sm-6 col-6">
				<div class="card-body d-flex flex-column p-0 pt-2">
					<div class="form-row mb-3">
						<div class="col">
							<input type="text" class="form-control" v-model="user.name" @input="validateName">
						</div>
						<div class="col">
							<input type="text" class="form-control" v-model="user.position" @input="validateName">
						</div>
						<div class="col">
							<input class="form-control" v-model="user.email" disabled>
						</div>
					</div>
					<div class="form-group">
						<textarea class="form-control" v-model="user.bio">{{ user.bio }}</textarea>
					</div>
					<small v-if="errorMessage"
					       class="form-text font-weight-bold text-danger text-center">
						{{ errorMessage }}
					</small>

					<div class="container-fluid mt-auto">
						<div class="row p-0">
							<div class="col-6 pr-1 pl-1">
								<button type="button" class="btn btn-success" v-bind:disabled="errorMessage || loading"
								        @click.prevent="updateData">
									<span v-if="loading" class="spinner-border spinner-border-sm"></span>
									<i class="bi bi-check-square"></i>
								</button>
							</div>

							<div class="col-6 pr-1 pl-1">
								<button type="button" class="btn btn-info" v-bind:disabled="loading"
								        @click.prevent="switchMode">
									<i class="bi bi-pencil-square"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import {useState} from '@/store/store'
import request from '@/request'
import consti from '@/const'
import Experience from '../components/Experience'
import CreateButton from '../components/CreateButton'
import CreateExperience from '../components/CreateExperience'

export default {
	name: 'ProfileInformation',
	components: {CreateExperience, CreateButton, Experience},
	setup() {
		return {
			state: useState()
		}
	},
	created() {
		if (!this.state.getUser().id) {
			this.fetchUserInfo()
		}
	},
  mounted() {
    document.title = 'BMS | Profile Information'
  },
	computed: {
		imageStyle() {
			return {
				'background': 'url("' + this.user.image + '")'
			}
		},
	},
	data() {
		return {
			user: this.state.getUser(),
			editMode: false,
			createMode: false,
			errorMessage: null,
			loading: false
		}
	},
	methods: {
		fetchUserInfo() {
			request.get(consti.baseUrl + consti.getUserInfo, this.$router)
				.then(data => {
					this.loading = false
					this.createMode = false

					this.user.id = data.id
					this.user.email = data.email
					this.user.name = data.name
					this.user.bio = data.bio
					this.user.position = data.position
					this.user.image = data.avatar
					this.user.experiences = data.experiences

					this.resetData()

					this.editMode = false
				}, error => {
					this.loading = false
				})
		},
		updateData() {

			this.loading = true

			let formData = new FormData();

			formData.append('_method', 'PUT')
			formData.append('name', this.user.name)
			formData.append('bio', this.user.bio)
			formData.append('position', this.user.position)

			if (this.user.image) {
				formData.append('avatar', this.user.image)
			}

			request.submitFormData(consti.baseUrl + consti.users + '/' + this.user.id, formData, {
				'Content-Type': 'multipart/form-data'
			}, this.$router)
				.then(data => {
					this.loading = false

					this.user.name = data.user.name
					this.user.bio = data.user.bio
					this.user.position = data.user.position
					this.user.image = data.user.avatar
					this.user.experiences = data.user.experiences

					this.resetData()

					this.editMode = false
				}, error => {
					this.loading = false
					this.user = this.store.getUser()
				})
		},
		createExperience() {
			this.createMode = !this.createMode
		},
		switchMode() {
			this.editMode = !this.editMode
			this.resetData()
			this.errorMessage = null
		},
		validateName() {
			if (this.user.name != null && this.user.name.length === 0) {
				this.errorMessage = "Your name can't be empty"
			} else {
				this.errorMessage = null
			}
		},
		onImageSelect(e) {
			const file = e.target.files[0]

			let formData = new FormData();
			formData.append('files[]', file)

			this.loading = true

			request.submitFormData(consti.baseUrl + consti.upload, formData, {
				'Content-Type': 'multipart/form-data'
			}, this.$router)
				.then(data => {
					this.loading = false
					this.user.image = data[0]
					this.errorMessage = null
				}, error => {
					this.loading = false
				})
		},
		resetData() {
			this.state.setUser(this.user)
		}
	}
}
</script>

<style scoped>

.card {
	padding: 1rem;
	margin: 1rem;
	height: 400px;
	display: inline-block;
	cursor: pointer;
}

.card:hover {
	background-color: #cfdeec;
}

textarea {
	resize: none;
	height: 120px;
}

.vertical-rule {
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	height: 100%;
}

.avatar {
	background-position: center;
	background-size: cover;
	height: 200px;
}

</style>
