<template>
  <button type="button" class="btn btn-info" @click.prevent="click">
    <i class="bi bi-plus-circle"></i>
  </button>
</template>

<script>
  export default {
    name: 'CreateButton',
    emits: ['onClick'],
    methods: {
      click() {
        this.$emit('onClick')
      }
    }
  }
</script>
