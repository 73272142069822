<template>
  <div class="bordered-section p-4">
    <div v-if="!editMode">
      <h5 class="card-title">Title: {{ title }}</h5>
      <h5 class="card-text">Description: {{ description }}</h5>
      <h5 class="card-title">Start Year: {{ startYear }}</h5>
      <h5 class="card-title">End Year: {{ endYear }}</h5>

      <edit-button @onClick="edit"></edit-button>
    </div>

    <div v-else>
      <div class="form-group">
        <input v-model="title" class="form-control" type="text" placeholder="Type title here...">
      </div>

      <div class="form-group">
        <textarea v-model="description" class="form-control">{{ description }}</textarea>
      </div>

      <div class="form-group">
        <input v-model="startYear" class="form-control" type="text" placeholder="Type start year here...">
      </div>

      <div class="form-group">
        <input v-model="endYear" class="form-control" type="text" placeholder="Type end year here...">
      </div>

      <div class="form-group">
        <div class="container">
          <div class="row">
            <div class="col-4 text-center">
              <save-button :loading="loading" @onClick="save"></save-button>
            </div>

            <div class="col-4 text-center">
              <edit-button @onClick="edit"></edit-button>
            </div>

            <div class="col-4 text-center">
              <button type="button" class="btn btn-danger" @click.prevent="deleteExperience">
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import EditButton from './EditButton'
  import SaveButton from './SaveButton'
  import request from '@/request'
  import consti from '@/const'

  export default {
    name: 'Experience',
    components: { SaveButton, EditButton },
    emits: ['onDeleted'],
    props: {
      id: {
        required: true,
        type: Number
      },
      title: {
        required: true,
        type: String
      },
      description: {
        required: true,
        type: String
      },
      startYear: {
        required: true,
        type: Number
      },
      endYear: {
        required: false,
        type: Number
      }
    },
    data() {
      return {
        editMode: false,
        loading: false
      }
    },
    methods: {
      save() {
        request.put(consti.baseUrl + consti.experiences + '/' + this.id, {
          title: this.title,
          description: this.description,
          start_year: this.startYear,
          end_year: this.endYear
        }, this.$router)
          .then(data => {
            this.loading = false
            this.editMode = false
          }, error => {
            this.loading = false
          })
      },
      edit() {
        this.editMode = !this.editMode
      },
      deleteExperience() {
        request.delete(consti.baseUrl + consti.experiences + '/' + this.id)
          .then(data => {
            this.loading = false
            this.editMode = false
            this.$emit('onDeleted')
          }, error => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped>

  .bordered-section {
    border-radius: 2px;
    border: 1px solid black;
  }

</style>
