<template>
  <div class="bordered-section p-4">
    <div class="form-group">
      <input v-model="title" class="form-control" type="text" placeholder="Type title here...">
    </div>

    <div class="form-group">
      <textarea v-model="description" class="form-control">{{ description }}</textarea>
    </div>

    <div class="form-group">
      <input v-model="startYear" class="form-control" type="text" placeholder="Type start year here...">
    </div>

    <div class="form-group">
      <input v-model="endYear" class="form-control" type="text" placeholder="Type end year here...">
    </div>

    <div class="form-group">
      <save-button :loading="loading" @onClick="create"></save-button>
    </div>
  </div>
</template>

<script>

  import SaveButton from './SaveButton'
  import request from '@/request'
  import consti from '@/const'

  export default {
    name: 'CreateExperience',
    emits: ['onCreated'],
    components: { SaveButton },
    data() {
      return {
        title: null,
        description: null,
        startYear: null,
        endYear: null,
        loading: false
      }
    },
    methods: {
      create() {
        request.post(consti.baseUrl + consti.experiences, {
          title: this.title,
          description: this.description,
          start_year: this.startYear,
          end_year: this.endYear
        }, this.$router)
          .then(data => {
            this.loading = false
            this.editMode = false
            this.reset()
            this.$emit('onCreated')
          }, error => {
            this.loading = false
          })
      },
      reset() {
        this.title = null
        this.description = null
        this.startYear = null
        this.endYear = null
        this.loading = null
      }
    }
  }
</script>

<style scoped>

  .bordered-section {
    border-radius: 2px;
    border: 1px solid black;
  }

</style>
